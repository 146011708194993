<template>
  <div class="boutiqueApp">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">精品应用</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="container" v-if="list.length">
        <div class="appItem" v-for="(i, j) in list" :key="j">
          <ImgDecypt class="appLogo" :src="i.officialImg" />
          <div class="appName">{{ i.officialName }}</div>
          <div class="appDesc">{{ i.officialDesc }}</div>
          <div class="appBtn" @click="downApp(i.officialUrl)">立即下载</div>
        </div>
      </div>
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import { officialList } from '@/api/mine';
import NoData from '@/components/NoData';
import ImgDecypt from '@/components/ImgDecypt';
import { Toast } from 'vant';
export default {
  name: 'BoutiqueApp',
  components: {
    ImgDecypt,
    NoData,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let req = { type: 1 }; // 精品应用-1 官方社群-2
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(officialList, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          // console.log(res)
          this.list = res.data;
        } else {
          Toast(res.tip || '请求失败');
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('请求失败');
      }
    },
    // 下载
    downApp(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.boutiqueApp {
  height: 100%;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 20px 22px;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    text-align: center;
    .appLogo {
      width: 82px;
      height: 82px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
    .appName {
      font-size: 14px;
      width: 82px;
      margin: 5px 0px;
    }
    .appDesc {
      font-size: 14px;
      width: 82px;
      margin: 5px 0px;
    }
    .appBtn {
      width: 82px;
      height: 26px;
      font-size: 12px;
      line-height: 26px;
      border-radius: 4px;
      text-align: center;
      color: #fff;
      background-color: rgb(255, 103, 143);
    }
  }
}
</style>
